body, html {
        background: url('./images/splash3.jpg')no-repeat center center fixed;
        width: 100vw;
        height: 100vh;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        margin: 0;
        
}     




/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

html, body {
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
   height:100%; /*A value other than height:auto needs to be set*/
}

body {
  position: relative
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.fade-in {
  animation: fadeIn ease 4s;
  -webkit-animation: fadeIn ease 4s;
  -moz-animation: fadeIn ease 4s;
  -o-animation: fadeIn ease 4s;
  -ms-animation: fadeIn ease 4s;
}

@keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

.polaroid {
  padding-top: 25px;
  background: #FFF;
  box-shadow: 0 0.2rem 1.2rem rgba(0,0,0,0.2);
  transform: rotate(-3deg);
}

.polaroid img{
  display: block;
  margin: auto;
  width: 85%;
  border: 2px rgb(177, 173, 173) solid;
  /* filter: grayscale(100%); */
}

.polaroid p {
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 2rem;
}

.card-deck {
  margin: auto;
}

.card-links {
  display: flex;
  justify-content: space-around;

}

@media screen and (max-height: 812px) {
    .MuiToolbar-gutters {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}


@media (max-width: 599.95px) and (min-width: 0px) {
  .carddeck {
      padding-right: 10% !important;
     
  }
  .card-deck{
  padding-right: 10% !important;
  }

}

@media (max-width: 599.95px) and (min-width: 0px) {
  .makeStyles-textField-4 {
    width: 100% !important;
  }
  .makeStyles-imageContainer-2 {
    margin: 0 auto !important;
    margin-top: 20% !important;
  }
  .home {
    padding-bottom: 4em !important;
  }

}

@media (max-width: 320px) and (min-width: 0px) {
  .makeStyles-buttonContainer-5 {
    padding-bottom: 6rem !important;
  }

  .makeStyles-imageContainer-2 {
    margin: 0 auto !important;
   
  }
}

@media (min-width: 768px){
.home{
    margin-top: 5em !important; 
  }
}
@media (min-width: 1023px){
.home{
    margin-top: 4em !important; 
  }
}


/* .navButtons {
  margin-right: 7px !important;
} */

.formContainer {
  padding-bottom: 5em !important;
}

@media (max-width: 599.95px) and (min-width: 0px){
  .iconBox {
    padding-bottom: 3em !important;
  }
}

@media (min-width: 540px ) and (max-width: 863px){
  /* .home {
    padding-top: 20em !important;
  } */
}

